import React from 'react';
import { styled } from 'styletron-react';

import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  fontSize: '1rem',
  lineHeight: 1.5,
  marginBottom: '3rem',
  ...typographyConstants.archivo,
});

const Heading = styled('h2', {
  display: 'inline',
  ...typographyConstants.archivoBlack,
});

const Text = styled('span', {});

export default function FileUnder({ text }) {
  return (
    <Container>
      <Heading>File Under</Heading> <Text>{text}</Text>
    </Container>
  );
}
