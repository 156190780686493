import { styled } from 'styletron-react';
import React from 'react';

import StyledLink from 'components/StyledLink';

import colorConstants from 'constants/colorConstants';
import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

const Container = styled('header', {
  alignItems: 'flex-start',
  color: colorConstants.darkBlue,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2.7rem',
});

const Logo = styled('div', {
  lineHeight: 1.325,

  [`${mediaConstants.desktop}`]: {
    marginBottom: 0,
  }
});

const titleStyles = {
  cursor: 'pointer',
  fontSize: '2rem',
  lineHeight: 1.2,
  textDecoration: 'none',
  ...typographyConstants.archivoBlack,

  [`${mediaConstants.desktop}`]: {
    fontSize: '3.2rem',
    lineHeight: 1,
  },
};

const Subtitle = styled('h2', {
  fontSize: '1.5rem',
  lineHeight: 1.325,
  ...typographyConstants.fleischWolf,
});

const Navigation = styled('nav', {
  display: 'flex',
  flex: '1 0 0%',
  justifyContent: 'flex-end',
  paddingTop: '0.5rem',
  textAlign: 'right',
});

const navLinkStyles = (active) => {
  return {
    borderBottom: (active ? `2px solid ${colorConstants.darkBlue}` : '2px solid transparent'),
    color: "colorConstants.darkBlue",
    fontSize: '1.5rem',
    marginRight: '4.5rem',
    paddingBottom: '0.3rem',
    position: 'relative',
    textDecoration: 'none',
    transition: 'all 0.2s ease-in-out',
    ...typographyConstants.fleischWurst,

    ':last-child': {
      marginRight: 0,
    },

    '::after': {
      width: (active ? '100%' : 0),
      height: '2px',
      position: 'absolute',
      bottom: '-2px',
      left: '0',
      content: '""',
      opacity: (active ? 1 : 0),
      transition: 'all 0.2s ease-in-out',
    },

    ':hover:after': {
      backgroundColor: colorConstants.darkBlue,
      opacity: 1,
      width: '100%',
    },
  };
}

export default class Header extends React.Component {
  render() {
    const {
      activeNavItem,
    } = this.props;

    return (
      <Container>
        <Logo>
          <h1>
            <StyledLink styles={titleStyles} to="/">
              Jen Dorman
            </StyledLink>
          </h1>

          <Subtitle>
            Creative Director
          </Subtitle>
        </Logo>

        <Navigation>
          <StyledLink styles={navLinkStyles(activeNavItem === 'Work')} to="/">
            Work
          </StyledLink>

          <StyledLink styles={navLinkStyles(activeNavItem === 'About')} to="/about">
            About
          </StyledLink>
        </Navigation>
      </Container>
    );
  }
}
