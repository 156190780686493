import React from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from 'styletron-react';

import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  fontSize: '1.5rem',
  lineHeight: 1.67,
  marginBottom: '1.5rem',
  ...typographyConstants.archivo,
});

export default function StorySection({ text }) {
  return (
    <Container className="StorySection">
      <ReactMarkdown source={text} />
    </Container>
  );
}
