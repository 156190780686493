import _isNull from 'lodash/isNull';
import React from 'react';
import posed from 'react-pose';
import Waypoint from 'react-waypoint';
import { styled } from 'styletron-react';

import AspectRatioContainer from 'components/AspectRatioContainer';
import AutoplayVideo from 'components/AutoplayVideo';
import ImagePreloader from 'components/ImagePreloader';
import VideoPreloader from 'components/VideoPreloader';

import typographyConstants from 'constants/typographyConstants';

const Image = styled(
  posed.img({
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 500,
        ease: 'easeOut',
      },
    },
  }),
  {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'transform 50ms ease-in-out',
    width: '100%',
    zIndex: 2,

    ':hover': {
      transform: 'scale(1.03) !important',
      cursor: 'pointer',
    },
  }
);

const VideoWrapper = styled(
  posed.div({
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 500,
        ease: 'easeOut',
      },
    },
  }),
  {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'transform 50ms ease-in-out',
    width: '100%',
    zIndex: 2,

    ':hover': {
      transform: 'scale(1.03) !important',
      cursor: 'pointer',
    },
  }
);

const videoStyles = {
  height: '100%',
  width: '100%',
};

const Loading = styled(
  posed.div({
    hidden: {
      applyAfterEnd: { display: 'none' },
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }),
  {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    fontSize: '2rem',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    ...typographyConstants.fleischWurst,
  }
);

export default class MediaObjectWithFade extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pose: 'hidden',
    };
  }

  handleClick = () => {
    this.props.onSelectWork(this.props.id);
  };

  handleEnter = () => {
    if (this.state.pose === 'hidden') {
      this.setState({
        pose: 'visible',
      });
    }
  };

  render() {
    const {
      aspectRatio,
      caption,
      containerStyles,
      parentPoseComplete,
      fileType,
      src,
      videoSources,
    } = this.props;

    const { pose } = this.state;

    return (
      <React.Fragment>
        {fileType === 'gif' && (
          <AspectRatioContainer
            aspectRatio={aspectRatio}
            $styles={containerStyles}
          >
            <Waypoint onEnter={this.handleEnter} topOffset="-50%" />
            {parentPoseComplete &&
              pose === 'visible' && (
                <VideoPreloader videoSources={videoSources}>
                  {({ isTakingAWhile, preloadedVideos, shouldFallback }) => {
                    const loadingPose = isTakingAWhile ? 'visible' : 'hidden';

                    return (
                      <React.Fragment>
                        {preloadedVideos && (
                          <VideoWrapper
                            aria-label={caption}
                            initialPose="hidden"
                            pose={pose}
                            onClick={this.handleClick}
                          >
                            <AutoplayVideo
                              styles={videoStyles}
                              videoObjects={preloadedVideos}
                            />
                          </VideoWrapper>
                        )}

                        <Loading initialPose="hidden" pose={loadingPose}>
                          Loading...
                        </Loading>
                      </React.Fragment>
                    );
                  }}
                </VideoPreloader>
              )}
          </AspectRatioContainer>
        )}

        {fileType !== 'gif' && (
          <AspectRatioContainer
            aspectRatio={aspectRatio}
            $styles={containerStyles}
          >
            <Waypoint onEnter={this.handleEnter} topOffset="-50%" />
            {parentPoseComplete &&
              pose === 'visible' && (
                <ImagePreloader src={src}>
                  {({ error, image, isTakingAWhile, loading }) => {
                    const loadingPose =
                      _isNull(image) && isTakingAWhile ? 'visible' : 'hidden';

                    return (
                      <React.Fragment>
                        {image && (
                          <Image
                            alt={caption}
                            initialPose="hidden"
                            pose={pose}
                            src={image}
                            onClick={this.handleClick}
                          />
                        )}

                        <Loading initialPose="hidden" pose={loadingPose}>
                          Loading...
                        </Loading>
                      </React.Fragment>
                    );
                  }}
                </ImagePreloader>
              )}
          </AspectRatioContainer>
        )}
      </React.Fragment>
    );
  }
}
