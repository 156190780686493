import _ from 'lodash';
import React from 'react';
import { HeadProvider, Title } from 'react-head';
import posed, { PoseGroup } from 'react-pose';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ProjectsContext from 'contexts/ProjectsContext';

import { Project } from 'models/Models';

import AboutRoute from 'routes/AboutRoute';
import IndexRoute from 'routes/IndexRoute';
import ProjectRoute from 'routes/ProjectRoute';

const transition = {
  duration: 1250,
  ease: 'easeInOut',
};

const RouteContainer = posed.div({
  enter: {
    applyAtStart: { zIndex: 2, position: 'fixed', width: '100%' },
    applyAtEnd: { zIndex: null, position: 'static', width: '100%' },
    y: 0,
    transition: transition,
  },
  exit: {
    applyAtStart: { zIndex: 0 },
    y: 0,
    transition: transition,
  },
  preEnter: {
    y: '200vh',
  },
});

export default function App() {
  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    const load = async () => {
      try {
        const response = await Project.includes([
          { campaigns: 'works' },
          'works',
        ])
          .order({ position: 'ASC' })
          .all();

        setProjects(response.data);
      } catch (e) {
        console.error(e);
      }
    };

    load();
  }, []);

  function handleAnimationComplete() {
    window.scrollTo(0, 0);
    window.dispatchEvent(new Event('scroll'));
  }

  return (
    <HeadProvider>
      <ProjectsContext.Provider value={projects}>
        <BrowserRouter>
          <Route
            render={props => {
              const pathnameParts = props.location.pathname.split('/');
              const routeContainerKey =
                _.reject(pathnameParts, part => {
                  return _.isEmpty(part);
                })[0] || 'index';

              return (
                <React.Fragment>
                  <Title>Jen Dorman</Title>

                  <PoseGroup preEnterPose="preEnter">
                    <RouteContainer
                      key={routeContainerKey}
                      onPoseComplete={handleAnimationComplete}
                    >
                      <Switch location={props.location}>
                        <Route
                          exact
                          path="/"
                          component={IndexRoute}
                          key="IndexRoute"
                        />
                        <Route
                          path="/about"
                          component={AboutRoute}
                          key="AboutRoute"
                        />
                        <Route
                          path="/projects"
                          component={ProjectRoute}
                          key="ProjectRoute"
                        />
                      </Switch>
                    </RouteContainer>
                  </PoseGroup>
                </React.Fragment>
              );
            }}
          />
        </BrowserRouter>
      </ProjectsContext.Provider>
    </HeadProvider>
  );
}
