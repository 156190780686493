import React from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from 'styletron-react';

import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  fontSize: '1.5rem',
  lineHeight: 1.67,
  marginBottom: '2.5rem',
  ...typographyConstants.archivoBlack,

  [`${mediaConstants.desktop}`]: {
    marginBottom: '1.5rem',
  },
});

export default function IntroSection({ text }) {
  return (
    <Container>
      <ReactMarkdown source={text} />
    </Container>
  );
}
