import React from 'react';
import { styled } from 'styletron-react';

const Container = styled('div', (props) => {
  return {
    cursor: 'pointer',
    letterSpacing: '3px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    whiteSpace: 'nowrap',
    fontSize: '2rem',
    visibility: props.$visibility,

    ...props.$textStyles,
  };
});

export default function Marquee(props) {
  const visibility = props.$visible ? 'visible' : 'hidden';

  return (
    <Container className="Marquee" $textStyles={props.$textStyles} $visibility={visibility}>
      {props.children}
    </Container>
  );
}
