import _forEach from 'lodash/forEach';
import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from 'styletron-react';

import ColumnHeading from 'components/ColumnHeading';

import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  fontSize: '1.5rem',
  paddingBottom: '6rem',

  [`${mediaConstants.desktop}`]: {
    fontSize: '1.2rem',
  },
});

const Body = styled('div', {
  lineHeight: 1.67,
  paddingTop: '1.5rem',
  ...typographyConstants.archivo,
});

export default function AboutBodySection(props) {
  const markdownRef = useRef(null);

  useEffect(() => {
    const links = markdownRef.current.querySelectorAll('a');

    _forEach(links, (link) => {
      link.classList.add('AboutLink');
      const textNode = link.firstChild;
      const span = document.createElement('span');
      span.appendChild(document.createTextNode(textNode.nodeValue));
      link.replaceChild(span, textNode);
    });
  }, []);

  return (
    <Container className="ProjectBodySection">
      <ColumnHeading>{props.heading}</ColumnHeading>

      <Body $ref={markdownRef}>
        <ReactMarkdown linkTarget="_blank" source={props.source} />
      </Body>
    </Container>
  );
}
