import _find from 'lodash/find';
import _filter from 'lodash/filter';
import React, { useState } from 'react';
import { Title, Link } from 'react-head';
import { styled } from 'styletron-react';

import Layout from 'components/Layout';
import ColumnHeading from 'components/ColumnHeading';
import ProjectList from 'components/ProjectList';
import ProjectPreview from 'components/ProjectPreview';
import GridRow from 'components/grid/Row';
import GridColumn from 'components/grid/Column';
import Screenwipe from 'components/Screenwipe';

import colorConstants from 'constants/colorConstants';
import mediaConstants from 'constants/mediaConstants';

import ProjectsContext from 'contexts/ProjectsContext';

import useWindowSize from 'hooks/useWindowSize';

import canonicalUrlUtil from 'utils/canonicalUrlUtil';

const PreviewContainer = styled('div', props => ({
  display: 'none',
  position: 'relative',

  [`${mediaConstants.desktop}`]: {
    display: 'block',
  },
}));

export default function IndexRoute(props) {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const windowSize = useWindowSize();
  const mainNumColumns = windowSize.width >= 1920 ? 9 : 8;
  const previewNumColumns = windowSize.width >= 1920 ? 3 : 4;

  return (
    <React.Fragment>
      <Title>Jen Dorman</Title>
      <Link
        rel="canonical"
        content={canonicalUrlUtil(props.location.pathname)}
      />

      <ProjectsContext.Consumer>
        {projects => {
          const filteredProjects = _filter(projects, { secret: false });
          const selectedProject = _find(projects, { id: selectedProjectId });

          return (
            <Layout
              activeNavItem="Work"
              backgroundColor={colorConstants.white}
              title="Work"
            >
              <Screenwipe />

              <GridRow>
                <GridColumn num={mainNumColumns}>
                  <ColumnHeading>Projects</ColumnHeading>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn num={mainNumColumns}>
                  <ProjectList
                    onSelectProject={setSelectedProjectId}
                    projects={filteredProjects}
                    selectedProjectId={selectedProjectId}
                  />
                </GridColumn>

                <GridColumn num={previewNumColumns}>
                  <PreviewContainer>
                    <ProjectPreview project={selectedProject} />
                  </PreviewContainer>
                </GridColumn>
              </GridRow>
            </Layout>
          );
        }}
      </ProjectsContext.Consumer>
    </React.Fragment>
  );
}
