import _map from 'lodash/map';
import React from "react";
import { styled } from "styletron-react";

import AspectRatioContainer from "components/AspectRatioContainer";
import AutoplayVideo from "components/AutoplayVideo";

const mediaStyles = {
  display: "block",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

const Image = styled("img", mediaStyles);

export default function MediaObject({
  aspectRatio,
  fileType,
  imageSrc,
  videoSources,
  $styles
}) {
  const videoObjects = _map(videoSources, (videoSource, key) => {
    return {
      src: videoSource,
      type: `video/${key}`
    };
  });

  return (
    <AspectRatioContainer aspectRatio={aspectRatio} $styles={$styles}>
      {fileType === "gif" && (
        <AutoplayVideo
          styles={mediaStyles}
          videoObjects={videoObjects}
        />
      )}

      {fileType !== "gif" && <Image src={imageSrc} />}
    </AspectRatioContainer>
  );
}
