import React from "react";
import { styled } from "styletron-react";

import Header from "components/Header";

import colorConstants from "constants/colorConstants";
import mediaConstants from "constants/mediaConstants";

const Wrapper = styled('div', (props) => ({
  paddingBottom: props.$extendBottom ? "25rem" : 0,

  [`${mediaConstants.desktop}`]: {
    paddingBottom: props.$extendBottom ? "15rem" : 0
  }
}));

const Container = styled("div", props => ({
  backgroundColor: props.$backgroundColor || colorConstants.white,
  minHeight: "100vh",
  position: "relative",
  zIndex: 2,
}));

const Frame = styled("div", {
  bottom: 0,
  boxShadow: `inset 0px 0px 0px 5px ${colorConstants.darkBlue}`,
  height: "100%",
  left: 0,
  pointerEvents: 'none',
  position: "fixed",
  right: 0,
  top: 0,
  width: "100%",
  zIndex: 999,
});

const Content = styled("div", {
  padding: "3rem",
  margin: "0 auto",
  zIndex: 2,

  [`${mediaConstants.desktop}`]: {
    padding: "4.4rem 6.9rem",
    maxWidth: "1200px"
  },

  [`${mediaConstants.widescreen}`]: {
    maxWidth: "1920px"
  }
});

export default class Layout extends React.Component {
  render() {
    const {
      activeNavItem,
      backgroundColor,
      children,
      extendBottom
    } = this.props;

    return (
      <Wrapper $extendBottom={extendBottom}>
        <Container
          $backgroundColor={backgroundColor}
          $extendBottom={extendBottom}
        >
          <Frame />

          <Content>
            <Header activeNavItem={activeNavItem} />

            {children}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}
