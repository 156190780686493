import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Route, Switch } from 'react-router-dom';
import { styled } from 'styletron-react';

import Layout from 'components/Layout';
import ProjectFooter from 'components/ProjectFooter';
import Screenwipe from 'components/Screenwipe';

import colorConstants from 'constants/colorConstants';

import ProjectDetailsRoute from 'routes/ProjectDetailsRoute';

const RouteContainer = styled(posed.div({
  enter: {
    applyAtStart: { zIndex: 2 },
  },
  exit: {
    applyAtStart: { zIndex: 1 }
  },
}), {
  position: 'relative'
});

export default function ProjectRoute(props) {
  return (
    <React.Fragment>
      <Layout
        activeNavItem='work'
        backgroundColor={colorConstants.white}
        extendBottom={true}
        title='Work'
      >
        <Screenwipe />

        <PoseGroup preEnterPose="preEnter">
          <RouteContainer key={props.location.pathname}>
            <Switch location={props.location}>
              <Route
                component={ProjectDetailsRoute}
                key="ProjectDetailsRoute"
                path="/projects/:projectSlug"
              />
            </Switch>
          </RouteContainer>
        </PoseGroup>
      </Layout>

      <ProjectFooter />
    </React.Fragment>
  );
}
