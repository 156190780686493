import React from "react";
import { styled } from "styletron-react";

import GridRow from "components/grid/Row";
import GridColumn from "components/grid/Column";
import StyledLink from "components/StyledLink";

import colorConstants from "constants/colorConstants";
import mediaConstants from "constants/mediaConstants";
import typographyConstants from "constants/typographyConstants";

const Container = styled("div", {
  backgroundColor: colorConstants.lightGreen,
  bottom: 0,
  height: "25rem",
  left: 0,
  position: "fixed",
  right: 0,
  width: "100%",
  zIndex: 1,

  [`${mediaConstants.desktop}`]: {
    height: "15rem"
  }
});

const Content = styled("div", {
  margin: "0 auto",
  padding: "3rem",

  [`${mediaConstants.desktop}`]: {
    maxWidth: "1200px",
    padding: "4.4rem 6.9rem"
  },

  [`${mediaConstants.widescreen}`]: {
    maxWidth: "1920px"
  }
});

const Nav = styled("nav", {
  paddingBottom: "4.1rem",

  [`${mediaConstants.desktop}`]: {
    display: "flex",
    paddingBottom: "3.3rem"
  }
});

const navLinkStyles = active => {
  return {
    borderBottom: active
      ? `2px solid ${colorConstants.darkBlue}`
      : "2px solid transparent",
    color: "colorConstants.darkBlue",
    display: "inline-block",
    fontSize: "1.5rem",
    position: "relative",
    textDecoration: "none",
    transition: "all 0.2s ease-in-out",
    ...typographyConstants.fleischWurst,

    ":last-child": {
      marginBottom: 0,
      marginRight: 0
    },

    "::after": {
      width: active ? "100%" : 0,
      height: "2px",
      position: "absolute",
      bottom: "-2px",
      left: "0",
      content: '""',
      opacity: active ? 1 : 0,
      transition: "all 0.2s ease-in-out"
    },

    ":hover:after": {
      backgroundColor: colorConstants.darkBlue,
      opacity: 1,
      width: "100%"
    },

    [`${mediaConstants.desktop}`]: {
      marginBottom: 0,
      marginRight: "9rem"
    }
  };
};

const Icon = styled("span", {
  fontSize: "1.2rem"
});

const Credits = styled("div", {
  fontSize: "1.2rem",
  lineHeight: 1.5
});

const Thing = styled("div", {
  marginBottom: "1.5rem",
  marginRight: "4.5rem",
});

export default function ProjectFooter() {
  return (
    <Container>
      <Content>
        <GridRow>
          <GridColumn num={9}>
            <Nav>
              <Thing>
                <StyledLink styles={navLinkStyles(false)} to="/">
                  Work
                </StyledLink>
              </Thing>

              <Thing>
                <StyledLink styles={navLinkStyles(false)} to="/about">
                  About + Contact
                </StyledLink>
              </Thing>

              <Thing>
                <StyledLink
                  styles={navLinkStyles(false)}
                  to="https://www.instagram.com/jeninpixels/"
                >
                  Instagram <Icon>↗</Icon>
                </StyledLink>
              </Thing>

              <Thing>
                <StyledLink
                  styles={navLinkStyles(false)}
                  to="https://www.linkedin.com/in/jenifer-dorman"
                >
                  LinkedIn <Icon>↗</Icon>
                </StyledLink>
              </Thing>
            </Nav>

            <Credits>
              Site design by Jen Dorman, development by{" "}
              <a href="http://blackholeinthemidwest.com">
                Black Hole in the Midwest
              </a>
              .
            </Credits>
          </GridColumn>
        </GridRow>
      </Content>
    </Container>
  );
}
