import _isNil from 'lodash/isNil';
import { styled } from 'styletron-react';
import posed from 'react-pose';

import mediaConstants from 'constants/mediaConstants';

const transition = (props) => {
  let duration = 500;

  if (!_isNil(props.selectedWorkId) && _isNil(props.previouslySelectedWorkId)) {
    duration = 0;
  } else if (_isNil(props.selectedWorkId) && !_isNil(props.previouslySelectedWorkId)) {
    duration = 0;
  }

  return {
    duration,
    ease: 'easeOut',
  }
};

export default styled(posed.div({
  hidden: {
    bottom: 0,
    left: 0,
    opacity: 0,
    right: 0,
    top: 0,
    width: "100%",
    zIndex: 0,
    transition,
  },

  nextWork: {
    bottom: 0,
    left: '85%',
    opacity: 0.4,
    right: 0,
    top: 0,
    width: '15%',
    zIndex: 2,
    transition,
  },

  nextNextWork: {
    bottom: 0,
    left: '100%',
    opacity: 0,
    right: 0,
    top: 0,
    width: '15%',
    zIndex: 0,
    transition,
  },

  prevWork: {
    bottom: 0,
    left: 0,
    opacity: 0.4,
    right: 0,
    top: 0,
    width: '15%',
    zIndex: 2,
    transition,
  },

  prevPrevWork: {
    bottom: 0,
    left: '-15%',
    opacity: 0,
    right: 0,
    top: 0,
    width: '15%',
    zIndex: 0,
    transition,
  },

  selectedWork: {
    bottom: 0,
    left: '15%',
    opacity: 1,
    right: 0,
    top: 0,
    width: '70%',
    zIndex: 1,
    transition,
  },
}), (props) => {
  let cursor = 'arrow';

  if (props.pose === 'prevWork') {
    cursor = "url('/arrow-left.svg') 22 22, pointer";
  }

  if (props.pose === 'nextWork') {
    cursor = "url('/arrow-right.svg') 22 22, pointer";
  }

  return {
    display: 'none',

    [`${mediaConstants.desktop}`]: {
      alignItems: 'center',
      cursor,
      display: 'flex',
      justifyContent: 'center',
      padding: '1.5rem',
      position: 'absolute',
      width: '70%',
    },
  }
});
