import _times from "lodash/times";
import { styled } from "styletron-react";
import React from "react";
import posed from "react-pose";
import { withRouter } from "react-router-dom";

import Marquee from "components/Marquee";
import ProjectPreview from "components/ProjectPreview";
import StyledLink from "components/StyledLink";

import colorConstants from "constants/colorConstants";
import mediaConstants from "constants/mediaConstants";
import typographyConstants from "constants/typographyConstants";

const containerStyles = props => ({
  backgroundColor: props.selected
    ? colorConstants.lightGreen
    : colorConstants.white,
  borderBottom: `1px solid ${colorConstants.darkBlue}`
});

const DesktopContainer = styled("li", props => ({
  display: "none",
  overflow: "hidden",
  paddingLeft: 0,
  position: "relative",
  width: "100%",
  ...containerStyles(props),

  [`${mediaConstants.desktop}`]: {
    display: "block"
  }
}));

const MobileContainer = styled("li", props => ({
  position: "relative",
  transition: "height 0.3s",
  borderBottom: `1px solid ${colorConstants.darkBlue}`,

  [`${mediaConstants.desktop}`]: {
    display: "none"
  }
}));

const MobileWrapper = styled("div", props => ({
  backgroundColor: props.selected
    ? colorConstants.lightGreen
    : colorConstants.white,
  margin: "0 -2.5rem",
  overflow: "hidden",
  padding: "0 2.5rem"
}));

const textStyles = {
  fontSize: "2rem",
  lineHeight: 1.1,
  padding: "1.5rem 0",
  ...typographyConstants.archivoBlack,

  [`${mediaConstants.desktop}`]: {
    fontSize: "2.4rem"
  },
};

const projectLinkStyles = props => ({
  display: "block",
  flex: "1 0 0%",
  minWidth: 0,
  textDecoration: "none",
  visibility: props.visibility,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...textStyles
});

const ProjectLink = styled("a", props => projectLinkStyles(props));

const transition = {
  damping: 100,
  stiffness: 100,
  type: "spring"
};

const TitleWrapper = styled("div", {
  alignItems: "center",
  display: "flex",
  overflow: 'hidden',
});

const ViewLink = styled(posed.div({
  open: { width: "auto", transition },
  closed: { width: 0, transition }
}), {
  flex: '0 0 auto',
  width: 0,
});

const viewLinkStyles = {
  display: "inline-block",
  fontSize: "1.5rem",
  padding: "1.5rem 1rem 1.5rem 1.5rem",
  textDecoration: "none",
  whiteSpace: "nowrap",
  ...typographyConstants.fleischWurst
};

const CollapsibleSection = styled(
  posed.div({
    open: { height: "auto", transition },
    closed: { height: 0, transition }
  }),
  {
    overflow: "hidden"
  }
);

class ProjectListItem extends React.Component {
  handleDeselectProject = () => {
    this.props.onSelectProject(null);
  };

  handleSelectProject = event => {
    event.preventDefault();
    this.props.onSelectProject(this.props.project.id);
  };

  handleToggleProject = event => {
    event.preventDefault();

    if (this.props.selected) {
      this.props.onSelectProject(null);
    } else {
      this.props.onSelectProject(this.props.project.id);
    }
  };

  handleGoToProject = event => {
    this.props.history.push(`/projects/${this.props.project.slug}`);
  };

  render() {
    const { project, selected, windowSize } = this.props;

    return (
      <React.Fragment>
        {windowSize.width < 1200 && (
          <MobileContainer selected={selected}>
            <MobileWrapper selected={selected}>
              <TitleWrapper>
                <ProjectLink
                  href={`/projects/${project.slug}`}
                  onClick={this.handleToggleProject}
                  selected={selected}
                  visibility="visible"
                >
                  {project.name}
                </ProjectLink>

                <ViewLink pose={selected ? "open" : "closed"}>
                  <StyledLink
                    styles={viewLinkStyles}
                    to={`/projects/${project.slug}`}
                  >
                    View →
                  </StyledLink>
                </ViewLink>
              </TitleWrapper>

              <CollapsibleSection
                initialPose="closed"
                pose={selected ? "open" : "closed"}
              >
                <ProjectPreview project={project} />
              </CollapsibleSection>
            </MobileWrapper>
          </MobileContainer>
        )}

        {windowSize.width >= 1200 && (
          <DesktopContainer
            onClick={this.handleGoToProject}
            onMouseEnter={this.handleSelectProject}
            onMouseLeave={this.handleDeselectProject}
            selected={selected}
          >
            <StyledLink
              styles={projectLinkStyles({
                selected,
                visibility: selected ? "hidden" : "visible"
              })}
              to={`/projects/${project.slug}`}
            >
              {project.name}
            </StyledLink>

            {selected && (
              <Marquee $textStyles={textStyles} $visible={selected}>
                {_times(10, n => (
                  <React.Fragment key={n}>
                    {project.name}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </React.Fragment>
                ))}
              </Marquee>
            )}
          </DesktopContainer>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ProjectListItem);
