import { styled } from 'styletron-react';

import mediaConstants from 'constants/mediaConstants';

export default styled('div', (props) => {
  return {
    [`${mediaConstants.desktop}`]: {
      gridColumn: `span ${props.num}`,
    },
  };
});
