import _findIndex from 'lodash/findIndex';
import _noop from 'lodash/noop';
import _map from 'lodash/map';

import { styled } from 'styletron-react';
import React from 'react';
import posed from 'react-pose';

import AutoplayVideo from 'components/AutoplayVideo';
import DesktopImageContainer from 'components/work-modal/DesktopImageContainer';
import MobileImageContainer from 'components/work-modal/MobileImageContainer';

import Vimeo from 'components/Vimeo';

import colorConstants from 'constants/colorConstants';
import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

const containerTransition = {
  duration: 500,
  ease: 'easeInOut',
  opacity: { duration: 500 },
};

const Container = styled(
  posed.div({
    open: {
      applyAtStart: { visibility: 'visible' },
      opacity: 1,
      y: '0%',
      transition: containerTransition,
    },
    closed: {
      applyAtEnd: { visibility: 'hidden' },
      opacity: 0,
      y: '100%',
      transition: containerTransition,
    },
  }),
  {
    backgroundColor: colorConstants.darkBlue,
    color: colorConstants.white,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    overflow: 'scroll',
    overscrollBehavior: 'contain',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  }
);

const contentTransition = {
  duration: containerTransition.duration / 2,
  ease: 'easeInOut',
};

const Content = styled(
  posed.div({
    open: {
      opacity: 1,
      transition: contentTransition,
    },
    closed: {
      opacity: 0,
      transition: contentTransition,
    },
  }),
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0 auto',
    position: 'relative',

    [`${mediaConstants.desktop}`]: {
      padding: '4.4rem 6.9rem 0 6.9rem',
      maxWidth: '1200px',
    },

    [`${mediaConstants.widescreen}`]: {
      maxWidth: '1920px',
    },
  }
);

const Carousel = styled('div', {
  flex: '1 0 0%',
  position: 'relative',

  [`${mediaConstants.desktop}`]: {
    margin: '0 -1.5rem',
  },
});

const BackgroundImage = styled('span', props => {
  const backgroundPosition = props.isMobile ? 'top center' : 'center center';

  return {
    backgroundImage: `url("${props.src}")`,
    backgroundPosition,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'block',
    width: '100%',
    height: '100%',

    [`${mediaConstants.desktop}`]: {
      height: '100%',
      width: '100%',
    },
  }

});

const Header = styled('header', {
  alignItems: 'baseline',
  borderBottom: `1px solid ${colorConstants.white}`,
  display: 'flex',
  flex: '0 0 auto',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '2.5rem 3rem 0 3rem',
  padding: '0 0 0.5rem 0',
  transition: 'height',
});

const ProjectTitle = styled('h2', {
  flex: '1 0 0%',
  fontSize: '2rem',
  marginBottom: '0.3rem',
  ...typographyConstants.fleischWolf,

  [`${mediaConstants.desktop}`]: {
    flex: '0 0 auto',
    order: 1,
  },
});

const CloseLink = styled('a', {
  color: colorConstants.white,
  cursor: 'pointer',
  flex: '0 0 auto',
  fontSize: '1.5rem',
  margin: '-1rem',
  padding: '1rem',
  ...typographyConstants.fleischWolf,

  [`${mediaConstants.desktop}`]: {
    order: 3,
  },
});

const Description = styled('div', {
  fontSize: '1.5rem',
  lineHeight: '1.67',
  flex: '0 0 100%',
  ...typographyConstants.archivo,

  [`${mediaConstants.desktop}`]: {
    flex: '1 0 0%',
    order: 2,
    padding: '0 5rem',
  },
});

const VideoWrapper = styled('div', props => {
  if (props.isMobile) {
    if (props.imageAspectRatio <= 100) {
      return {
        paddingTop: `${props.imageAspectRatio}%`,
        position: 'relative',
        width: '100%',
      }
    } else {
      return {
        width: '100%',
        // maxHeight: '100%',
        paddingTop: '100%',
        position: 'relative',
      }
    }
  } else {
    return {
      height: '100%',
      position: 'relative',
      width: '100%',
    }
  }
});

export default class WorkModal extends React.Component {
  state = {
    hasBeenOpened: false,
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkId } = this.props;

    const { hasBeenOpened } = this.state;

    if (
      prevProps.selectedWorkId === null &&
      selectedWorkId !== null &&
      !hasBeenOpened
    ) {
      this.setState({
        hasBeenOpened: true,
      });
    }
  }

  getContainerPose = () => {
    return this.props.selectedWorkId ? 'open' : 'closed';
  };

  handleClose = () => {
    this.props.onSelectWork(null);
  };

  handleCleanup = () => {
    if (this.getContainerPose() === 'closed') {
      this.props.onSelectWork(null);
    }
  };

  render() {
    const {
      onSelectWork,
      previouslySelectedWorkId,
      projectName,
      selectedWorkId,
      windowSize,
      works,
    } = this.props;

    const { hasBeenOpened } = this.state;

    let selectedWorkIndex;

    if (selectedWorkId) {
      selectedWorkIndex = _findIndex(works, { id: selectedWorkId });
    } else {
      selectedWorkIndex = _findIndex(works, { id: previouslySelectedWorkId });
    }

    const nextIndex = selectedWorkIndex + 1;
    const prevIndex = selectedWorkIndex - 1;
    const nextNextIndex = nextIndex - 1;
    const prevPrevIndex = prevIndex - 1;
    const selectedWork = works[selectedWorkIndex];

    const handleClickNext = () => {
      onSelectWork(works[nextIndex].id);
    };

    const handleClickPrev = () => {
      onSelectWork(works[prevIndex].id);
    };

    const containerPose = this.getContainerPose();

    const isMobile = windowSize.width < 1200;

    return (
      <Container
        initialPose="closed"
        onPoseComplete={this.handleCleanup}
        pose={containerPose}
      >
        <Content>
          <Header>
            <ProjectTitle>{projectName}</ProjectTitle>

            <CloseLink onClick={this.handleClose}>Close</CloseLink>

            {selectedWork && <Description>{selectedWork.caption}</Description>}
          </Header>

          <Carousel>
            {_map(works, (work, index) => {
              let pose;
              let onClick = _noop;

              if (index === selectedWorkIndex) {
                pose = 'selectedWork';
              } else if (index === nextIndex) {
                pose = 'nextWork';
                onClick = handleClickNext;
              } else if (index >= nextNextIndex) {
                pose = 'nextNextWork';
              } else if (index === prevIndex) {
                pose = 'prevWork';
                onClick = handleClickPrev;
              } else if (index <= prevPrevIndex) {
                pose = 'prevPrevWork';
              } else {
                pose = 'hidden';
              }

              if (!hasBeenOpened) return null;

              const conditional = (work.workType === 'image' ||
                (work.workType === 'video' && pose !== 'selectedWork'));

              let videoStyles = {
                height: '100%',
                width: '100%',
              };

              if (isMobile) {
                videoStyles = {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                };
              }

              const content = (
                <React.Fragment>
                  {conditional && (
                    <React.Fragment>
                      {work.imageFileType === 'gif' && (
                        <VideoWrapper imageAspectRatio={work.imageAspectRatio} isMobile={isMobile} work={work}>
                          <AutoplayVideo
                            styles={videoStyles}
                            videoObjects={_map(
                              work.imageVideoSources,
                              (videoSource, key) => {
                                return {
                                  src: videoSource,
                                  type: `video/${key}`,
                                };
                              }
                            )}
                          />
                        </VideoWrapper>
                      )}

                      {work.imageFileType !== 'gif' && (
                        <BackgroundImage
                          aria-label={work.caption}
                          isMobile={isMobile}
                          role="img"
                          src={work.imageSrc}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {work.workType === 'video' &&
                    pose === 'selectedWork' && (
                      <Vimeo
                        isMobile={isMobile}
                        selected={pose === 'selectedWork'}
                        work={work}
                        onClose={this.handleClose}
                      />
                    )}
                </React.Fragment>
              );

              return (
                <React.Fragment key={work.id}>
                  {isMobile && (
                    <MobileImageContainer
                      className="MobileImageContainer"
                      initialPose={pose}
                      key={`mobile-${work.id}`}
                      onClick={onClick}
                      pose={pose}
                      previouslySelectedWorkId={previouslySelectedWorkId}
                      selectedWorkId={selectedWorkId}
                    >
                      {content}
                    </MobileImageContainer>
                  )}

                  {!isMobile && (
                    <DesktopImageContainer
                      className="DesktopImageContainer"
                      initialPose="hidden"
                      key={`desktop-${work.id}`}
                      onClick={onClick}
                      pose={pose}
                      previouslySelectedWorkId={previouslySelectedWorkId}
                      selectedWorkId={selectedWorkId}
                    >
                      {content}
                    </DesktopImageContainer>
                  )}
                </React.Fragment>
              );
            })}
          </Carousel>
        </Content>
      </Container>
    );
  }
}
