import _map from "lodash/map";
import React from "react";
import { styled } from "styletron-react";

import Row from "components/works-grid/Row";
import MediaObjectWithFade from "components/MediaObjectWithFade";

const Container = styled("div", props => {
  return {
    gridColumn: `span ${props.width}`,
  };
});

const Work = styled("div", {
  marginBottom: '2.2rem',

  ':last-child': {
    marginBottom: 0
  }
});

export default function Column({ column, onSelectWork, parentPoseComplete }) {
  if (!column) {
    return null;
  }

  return (
    <Container width={column.width}>
      {_map(column.rows, row => {
        return (
          <Row
            key={row.project_row_id}
            onSelectWork={onSelectWork}
            parentPoseComplete={parentPoseComplete}
            row={row}
          />
        );
      })}

      {_map(column.works, work => {
        return (
          <Work key={work.id}>
            <MediaObjectWithFade
              aspectRatio={work.image_aspect_ratio}
              caption={work.caption}
              fileType={work.image_file_type}
              id={work.id}
              onSelectWork={onSelectWork}
              parentPoseComplete={parentPoseComplete}
              src={work.image_src}
              videoSources={work.image_video_sources}
            />
          </Work>
        );
      })}
    </Container>
  );
}
