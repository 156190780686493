import _map from "lodash/map";
import React from "react";

import MediaObjectWithFade from "components/MediaObjectWithFade";

const containerStyles = {
  marginBottom: "3rem"
};

export default class MobileWorks extends React.Component {
  render() {
    const { onSelectWork, parentPoseComplete, works } = this.props;

    return _map(works, work => (
      <MediaObjectWithFade
        aspectRatio={work.imageAspectRatio}
        caption={work.caption}
        containerStyles={containerStyles}
        fileType={work.imageFileType}
        id={work.id}
        key={work.id}
        onSelectWork={onSelectWork}
        parentPoseComplete={parentPoseComplete}
        src={work.imageSrc}
        videoSources={work.imageVideoSources}
      />
    ));
  }
}
