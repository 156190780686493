import { styled } from 'styletron-react';
import React from 'react';

import colorConstants from 'constants/colorConstants';
import typographyConstants from 'constants/typographyConstants';

const Container = styled('h2', {
  alignItems: 'center',
  borderBottom: `1px solid ${colorConstants.darkBlue}`,
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '1.5rem',
  ...typographyConstants.fleischWolf,
});

const Text = styled('span', {
  flex: '1 0 0%',
  fontSize: '1.5rem',
  padding: '0 0.6rem 0 0',
});

export default class Heading extends React.Component {
  render() {
    const {
      children,
    } = this.props;

    return (
      <Container>
        <Text>
          {children}
        </Text>
      </Container>
    );
  }
}
