import _join from 'lodash/join';
import _map from 'lodash/map';
import { styled } from 'styletron-react';
import React from 'react';

function AutoplayVideo({ className, videoObjects }) {
  const sourceHtml = _join(
    _map(videoObjects, videoObject => {
      return `<source src="${videoObject.src}" type="${videoObject.type}" />`;
    }),
    ' '
  );

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: `
          <video
            autoplay
            class="${className}"
            loop
            muted
            playsinline
            preload="auto"
          >
            ${sourceHtml}
          </video>
        `,
      }}
    />
  );
}

export default styled(AutoplayVideo, props => {
  if (typeof props.styles === 'function') {
    return props.styles(props);
  } else {
    return {
      ...props.styles,
    };
  }
});
