export default function indexUtil(array, index) {
  let nextIndex = index + 1;

  while (true) {
    if (nextIndex === index) {
      break;
    }

    if (nextIndex > array.length - 1) {
      nextIndex = 0;
    }

    if (array[nextIndex].secret === true) {
      nextIndex = nextIndex + 1;
    } else {
      break;
    }
  }

  let prevIndex = index - 1;

  while (true) {
    if (prevIndex === index) {
      break;
    }

    if (prevIndex < 0) {
      prevIndex = array.length - 1;
    }

    if (array[prevIndex].secret === true) {
      prevIndex = prevIndex - 1;
    } else {
      break;
    }
  }

  return [nextIndex, prevIndex];
}
