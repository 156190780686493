import { useEffect, useReducer } from 'react';

import useTimeout from 'hooks/useTimeout';

function reducer(state, action) {
  switch (action.type) {
    case 'loaded':
      return {
        ...state,
        loading: false,
      };
    case 'errored':
      return {
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}

export default function ImagePreloader(props) {
  const { src } = props;

  const [state, dispatch] = useReducer(reducer, {
    error: false,
    loading: true,
  });

  const { error, loading } = state;

  useEffect(() => {
    const imageToPreload = new Image();

    imageToPreload.onload = () => {
      dispatch({ type: 'loaded' });
    };

    imageToPreload.onerror = () => {
      dispatch({ type: 'errored' });
    };

    imageToPreload.src = src;

    return () => {
      imageToPreload.onload = null;
      imageToPreload.onerror = null;
    };
  }, []);

  const isTakingAWhile = useTimeout(2000);

  if (state.loading) {
    return props.children({
      error,
      image: null,
      isTakingAWhile,
      loading,
    });
  } else {
    return props.children({
      error,
      image: src,
      isTakingAWhile,
      loading,
    });
  }
}
