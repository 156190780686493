import React from 'react';
import { Title, Link } from 'react-head';

import AboutWrapper from 'components/AboutWrapper';
import Layout from 'components/Layout';
import Screenwipe from 'components/Screenwipe';

import colorConstants from 'constants/colorConstants';

import { AboutContent } from 'models/Models';

import canonicalUrlUtil from 'utils/canonicalUrlUtil';

export default class AboutRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutContent: null,
    };
  }

  async componentDidMount() {
    document.querySelector('body').classList.add('lightGreen');

    try {
      const response = await AboutContent.all();

      this.setState({
        aboutContent: response.data[0],
      });
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('lightGreen');
  }

  render() {
    const { location } = this.props;
    const { aboutContent } = this.state;

    return (
      <React.Fragment>
        <Title>About + Contact | Jen Dorman</Title>
        <Link rel="canonical" content={canonicalUrlUtil(location.pathname)} />

        <Layout
          activeNavItem="About"
          backgroundColor={colorConstants.lightGreen}
          title="About + Contact"
        >
          <Screenwipe />

          {aboutContent && <AboutWrapper aboutContent={aboutContent} />}
        </Layout>
      </React.Fragment>
    );
  }
}
