import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';
import { Title, Link } from 'react-head';
import posed from 'react-pose';

import ProjectDetails from 'components/ProjectDetails';

import ProjectsContext from 'contexts/ProjectsContext';

import canonicalUrlUtil from 'utils/canonicalUrlUtil';
import indexUtil from 'utils/indexUtil';

const Container = posed.div({
  enter: {
    opacity: 1,
    transition: {
      duration: 750,
      ease: 'easeIn',
    },
    onPoseComplete: props => {
      props.$onEnterComplete(true);
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 750,
      ease: 'easeOut',
    },
  },
  preEnter: {
    opacity: 0,
  },
});

export default function ProjectDetailsRoute(props) {
  const projects = useContext(ProjectsContext);

  if (_isEmpty(projects)) {
    return null;
  }

  const slug = props.match.params.projectSlug;

  const projectIndex = _findIndex(projects, { slug });
  const [nextIndex, prevIndex] = indexUtil(projects, projectIndex);
  const project = projects[projectIndex];
  const nextProject = projects[nextIndex];
  const prevProject = projects[prevIndex];

  const [poseComplete, setPoseComplete] = useState(false);

  return (
    <React.Fragment>
      <Title>{project.name} | Jen Dorman</Title>
      <Link
        rel="canonical"
        content={canonicalUrlUtil(props.location.pathname)}
      />

      <Container $onEnterComplete={setPoseComplete}>
        <ProjectDetails
          history={props.history}
          poseComplete={poseComplete}
          project={project}
          slugNext={nextProject.slug}
          slugPrev={prevProject.slug}
        />
      </Container>
    </React.Fragment>
  );
}
