import React from "react";
import { styled } from "styletron-react";

import MediaObject from "components/MediaObject";
import ProjectBodySection from "components/ProjectBodySection";
import StyledLink from "components/StyledLink";

const Container = styled("div", {
  marginTop: "-1px"
});

const linkStyles = {
  display: 'block',
};

const imageContainerStyles = {
  marginBottom: "2.5rem"
};

export default class ProjectPreview extends React.Component {
  handleSelectProject = () => {
    this.props.onSelectProject(this.props.project.id);
  };

  render() {
    const { project } = this.props;

    if (!project) {
      return null;
    }

    return (
      <Container>
        <StyledLink styles={linkStyles} to={`/projects/${project.slug}`}>
          <MediaObject
            aspectRatio={project.previewImageAspectRatio}
            fileType={project.previewImageFileType}
            imageSrc={project.previewImageSrc}
            videoSources={project.previewImageVideoSources}
            $styles={imageContainerStyles}
          />
        </StyledLink>

        <ProjectBodySection heading="File Under" source={project.file} />
      </Container>
    );
  }
}
