import { styled } from 'styletron-react';
import React from 'react';

const Container = styled('div', (props) => ({
  ...props.$styles,
  position: 'relative',
}));

export default function AspectRatioContainer(props) {
  const {
    aspectRatio,
    children,
    $styles,
  } = props;

  return (
    <Container style={{ paddingBottom: `${aspectRatio}%` }} $styles={$styles}>
      {children}
    </Container>
  );
}
