import _map from 'lodash/map';
import React from 'react';
import { styled } from 'styletron-react';

import Row from 'components/works-grid/Row';

import colorConstants from 'constants/colorConstants';
import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  marginBottom: '3rem',

  ':last-child': {
    marginBottom: 0,
  },
});
const Heading = styled('div', {
  borderBottom: `1px solid ${colorConstants.darkBlue}`,
  fontSize: '1.5rem',
  padding: '1.5rem 0',
  ...typographyConstants.fleischWolf,
});

export default function DesktopWorks({
  campaignTitle,
  index,
  onSelectWork,
  parentPoseComplete,
  worksGrid,
}) {
  return (
    <Container>
      {index > 0 && <Heading>{campaignTitle}</Heading>}

      {_map(worksGrid, row => {
        return (
          <Row
            key={row.project_row_id}
            onSelectWork={onSelectWork}
            parentPoseComplete={parentPoseComplete}
            row={row}
          />
        );
      })}
    </Container>
  );
}
