import _ from 'lodash';

import useTimeout from 'hooks/useTimeout';

export default function VideoPreloader(props) {
  const { videoSources } = props;

  const preloadedVideos = _.map(videoSources, (videoSource, key) => ({
    src: videoSource,
    type: `video/${key}`,
  }));

  const isTakingAWhile = useTimeout(1000);

  return props.children({
    preloadedVideos,
    isTakingAWhile,
  });
}
