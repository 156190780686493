import React, { useEffect } from 'react';
import { styled } from 'styletron-react';
import Player from '@vimeo/player';

const Container = styled('div', props => {
  if (props.isMobile) {
    if (props.work.imageAspectRatio <= 100) {
      return {
        paddingTop: `${props.work.imageAspectRatio}%`,
        position: 'relative',
        width: '100%',
      }
    } else {
      return {
        paddingTop: '100%',
        position: 'relative',
        width: '100%',
      }
    }
  } else {
    return {
      height: '100%',
      width: '100%',
    }
  }
});

export default function Vimeo(props) {
  const {
    isMobile,
    selected,
    work,
  } = props;

  useEffect(() => {
    const player = new Player(`video-${work.id}`, {
      url: work.video,
      width: null,
      height: null,
    });

    if (selected === true) {
      player.play();
    } else {
      player.pause();
    }

    return () => {
      player.destroy();
    }
  }, [selected]);

  return (
    <Container className='VimeoPlayer' data-vimeo-maxheight="100%" data-vimeo-maxwidth="100%" id={`video-${work.id}`} isMobile={isMobile} work={work} />
  );
}
