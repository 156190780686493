import { styled } from 'styletron-react';
import React from 'react';
import { Events, animateScroll as scroll } from 'react-scroll';

import StyledLink from 'components/StyledLink';

import colorConstants from 'constants/colorConstants';
import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

const Wrapper = styled('div', {
  borderBottom: `1px solid ${colorConstants.darkBlue}`,
  position: 'relative',
  zIndex: 10,
});

const Container = styled('h2', {
  alignItems: 'center',
  backgroundColor: colorConstants.white,
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 -2.5rem',
  padding: '1.5rem 2.5rem',
  ...typographyConstants.fleischWolf,
});

const Text = styled('span', {
  flex: '1 0 0%',
  fontSize: '1.5rem',
  padding: '0 0.6rem 0 0',
});

const Navigation = styled('nav', {
  flex: '0 0 auto',

  [`${mediaConstants.widescreen}`]: {
    display: 'none',
  },
});

const navLinkStyles = {
  display: 'inline-block',
  fontSize: '1.2rem',
  margin: '0 1.2rem',
  textDecoration: 'none',

  ':first-child': {
    marginLeft: 0,
  },

  ':last-child': {
    marginRight: 0,
  },
};

export default class ProjectHeading extends React.Component {
  handleClickNext = event => {
    event.preventDefault();

    Events.scrollEvent.register('end', (to, element) => {
      this.props.history.push(`/projects/${this.props.slugNext}`);
    });

    scroll.scrollToTop({
      duration: 500,
    });
  };

  handleClickPrev = event => {
    event.preventDefault();

    Events.scrollEvent.register('end', (to, element) => {
      this.props.history.push(`/projects/${this.props.slugPrev}`);
    });

    scroll.scrollToTop({
      duration: 500,
    });
  };

  componentWillUnmount() {
    Events.scrollEvent.remove('end');
  }

  render() {
    const { children, slugNext, slugPrev, style } = this.props;

    return (
      <Wrapper style={style}>
        <Container>
          <Text>{children}</Text>

          <Navigation>
            <StyledLink
              styles={navLinkStyles}
              to={`/projects/${slugPrev}`}
              onClick={this.handleClickPrev}
            >
              {'<'} Prev
            </StyledLink>

            <StyledLink
              styles={navLinkStyles}
              to={`/projects/${slugNext}`}
              onClick={this.handleClickNext}
            >
              Next {'>'}
            </StyledLink>
          </Navigation>
        </Container>
      </Wrapper>
    );
  }
}
