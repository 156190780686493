import { styled } from 'styletron-react';

import colorConstants from 'constants/colorConstants';

export default styled('div', {
  backgroundColor: colorConstants.darkBlue,
  position: 'absolute',
  right: 0,
  bottom: '100%',
  left: 0,
  width: '100%',
  height: 'calc(100vh)',
});
