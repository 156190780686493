import { styled } from 'styletron-react';
import React from 'react';
import { Link } from 'react-router-dom';

const component = ({ children, className, onClick, state, to }) => {
  const parser = document.createElement('a');
  parser.href = to;
  const isInternal = window.location.host === parser.host;

  if (isInternal) {
    return (
      <Link className={className} onClick={onClick} state={state} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <a className={className} onClick={onClick} href={to}>
      {children}
    </a>
  );
};

export default styled(component, props => {
  if (typeof props.styles === 'function') {
    return props.styles(props);
  } else {
    return {
      ...props.styles,
    };
  }
});
