import { attr, belongsTo, hasMany } from 'jsorm';

import ApplicationRecord from 'models/ApplicationRecord';

export const Project = ApplicationRecord.extend({
  attrs: {
    campaigns: hasMany(),
    works: hasMany(),

    file: attr(),
    intro: attr(),
    images: attr(),
    name: attr(),
    position: attr(),
    previewImageAspectRatio: attr(),
    previewImageFileType: attr(),
    previewImageSrc: attr(),
    previewImageVideoSources: attr(),
    results: attr(),
    secret: attr(),
    slug: attr(),
    story: attr(),
  },
  static: {
    jsonapiType: 'projects',
  },
});

export const Campaign = ApplicationRecord.extend({
  attrs: {
    project: belongsTo(),

    position: attr(),
    title: attr(),
    worksGrid: attr(),
  },
  static: {
    jsonapiType: 'campaigns',
  },
});

export const Work = ApplicationRecord.extend({
  attrs: {
    project: belongsTo(),

    caption: attr(),
    imageAspectRatio: attr(),
    imageFileType: attr(),
    imageSrc: attr(),
    imageVideoSources: attr(),
    imageHeight: attr(),
    imageWidth: attr(),
    workType: attr(),
    video: attr(),
  },
  static: {
    jsonapiType: 'works',
  },
});

export const AboutContent = ApplicationRecord.extend({
  attrs: {
    about: attr(),
    contact: attr(),
    imageAspectRatio: attr(),
    imageSrc: attr(),
  },
  static: {
    jsonapiType: 'about_contents',
  },
});
