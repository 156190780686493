import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Client } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from 'styletron-react';

const styletron = new Client();

ReactDOM.render(
  <StyletronProvider value={styletron}>
    <App />
  </StyletronProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default

      ReactDOM.render(
        <StyletronProvider value={styletron}>
          <NextApp />
        </StyletronProvider>,
        document.getElementById('root')
      )
    })
  }
}
