import _isNil from "lodash/isNil";
import { styled } from "styletron-react";
import posed from "react-pose";

import mediaConstants from "constants/mediaConstants";

const transition = props => {
  let duration = 500;

  if (!_isNil(props.selectedWorkId) && _isNil(props.previouslySelectedWorkId)) {
    duration = 0;
  } else if (
    _isNil(props.selectedWorkId) &&
    !_isNil(props.previouslySelectedWorkId)
  ) {
    duration = 0;
  }

  return {
    duration,
    ease: "easeInOut"
  };
};

export default styled(
  posed.div({
    hidden: {
      bottom: "0",
      height: "25%",
      opacity: 0,
      top: "75%",
      width: "50%",
      zIndex: 0,
      transition
    },

    nextWork: {
      alignItems: "flex-end",
      bottom: "0",
      height: "25%",
      left: "50%",
      opacity: 0.4,
      top: "75%",
      width: "50%",
      zIndex: 2,
      transition
    },

    nextNextWork: {
      bottom: "0",
      height: "25%",
      left: "75%",
      opacity: 0,
      top: "75%",
      width: "50%",
      zIndex: 0,
      transition
    },

    prevWork: {
      alignItems: "flex-start",
      bottom: "0",
      height: "25%",
      left: 0,
      opacity: 0.4,
      top: "75%",
      width: "50%",
      zIndex: 2,
      transition
    },

    prevPrevWork: {
      bottom: "0",
      height: "25%",
      left: "-25%",
      opacity: 0,
      top: "75%",
      width: "50%",
      zIndex: 0,
      transition
    },

    selectedWork: {
      bottom: "25%",
      height: "75%",
      left: 0,
      opacity: 1,
      top: 0,
      width: "100%",
      zIndex: 1,
      transition
    }
  }),
  {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    padding: "1.5rem",
    position: "absolute",

    [`${mediaConstants.desktop}`]: {
      display: "none"
    }
  }
);
