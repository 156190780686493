import { JSORMBase } from 'jsorm';

const ApplicationRecord = JSORMBase.extend({
  static: {
    baseUrl: "",
    apiNamespace: "/api/v1"
  }
});

export default ApplicationRecord;
