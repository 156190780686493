import _ from 'lodash';
import _map from 'lodash/map';
import React from 'react';
import { styled } from 'styletron-react';

import Column from 'components/works-grid/Column';

const Container = styled('div', props => {
  const gridTemplateColumns = _.join(
    _.map(_.range(props.$totalWidth), () => '1fr'),
    ' '
  );

  return {
    display: 'grid',
    gridAutoRows: 'auto',
    gridGap: '3rem',
    gridTemplateColumns,
    padding: '1.1rem 0',
    width: '100%',
  };
});

export default function Row({ onSelectWork, parentPoseComplete, row }) {
  return (
    <Container $totalWidth={row.total_width}>
      {_map(row.columns, column => {
        return (
          <Column
            column={column}
            key={column.project_column_id}
            onSelectWork={onSelectWork}
            parentPoseComplete={parentPoseComplete}
          />
        );
      })}
    </Container>
  );
}
