import { styled } from 'styletron-react';
import React from 'react';

import mediaConstants from 'constants/mediaConstants';

const Container = styled('div', {
  [`${mediaConstants.desktop}`]: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: '3rem',
  },
});

export default function Row({ children }) {
  return <Container>{children}</Container>;
}
