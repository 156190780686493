export default {
  fleischWurst: {
    fontFamily: 'fleisch-wurst,sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
  },

  fleischWolf: {
    fontFamily: 'fleisch-wolf,sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
  },

  archivoBlack: {
    fontFamily: "'Archivo Black', sans-serif",
    fontWeight: '400',
  },

  archivo: {
    fontFamily: "'Archivo', sans-serif",
  }
};
