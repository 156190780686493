import _forEach from 'lodash/forEach';
import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from 'styletron-react';

import typographyConstants from 'constants/typographyConstants';

const Container = styled('div', {
  fontSize: '1.5rem',
  lineHeight: 1.67,
  paddingBottom: '2.5rem',
});

const Heading = styled('h4', {
  fontWeight: 400,
  ...typographyConstants.archivoBlack,
});

const Body = styled('div', {
  ...typographyConstants.archivo,
});

export default function ProjectBodySection(props) {
  const markdownRef = useRef(null);

  useEffect(() => {
    const links = markdownRef.current.querySelectorAll('a');

    _forEach(links, link => {
      const textNode = link.firstChild;
      const span = document.createElement('span');
      span.appendChild(document.createTextNode(textNode.nodeValue));
      link.replaceChild(span, textNode);
    });
  }, []);

  return (
    <Container className="ProjectBodySection">
      <Heading>{props.heading}</Heading>

      <Body $ref={markdownRef}>
        <ReactMarkdown source={props.source} />
      </Body>
    </Container>
  );
}
