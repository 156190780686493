import { styled } from 'styletron-react';
import React from 'react';

import GridRow from 'components/grid/Row';
import GridColumn from 'components/grid/Column';

import AspectRatioContainer from 'components/AspectRatioContainer';
import AboutBodySection from 'components/AboutBodySection';

import mediaConstants from 'constants/mediaConstants';

import useWindowSize from 'hooks/useWindowSize';

const Picture = styled('img', {
  display: 'block',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
});

const pictureContainerStyles = {
  marginBottom: '4.5rem',

  [`${mediaConstants.desktop}`]: {
    marginBottom: 0,
  },
};

export default function AboutWrapper(props) {
  const { width } = useWindowSize();

  const numColumnsLeft = width >= 1920 ? 3 : 6;

  return (
    <GridRow>
      <GridColumn num={numColumnsLeft}>
        <AboutBodySection heading="About" source={props.aboutContent.about} />
      </GridColumn>

      <GridColumn num={3}>
        <AboutBodySection heading="Contact" source={props.aboutContent.contact} />
      </GridColumn>

      <GridColumn num={3}>
        <AspectRatioContainer
          aspectRatio={props.aboutContent.imageAspectRatio}
          $styles={pictureContainerStyles}>
          <Picture src={props.aboutContent.imageSrc} />
        </AspectRatioContainer>
      </GridColumn>
    </GridRow>
  );
}
