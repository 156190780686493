import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _slice from 'lodash/slice';
import _times from 'lodash/times';
import React, { useState } from 'react';
import { Events, animateScroll as scroll } from 'react-scroll';
import { StickyContainer, Sticky } from 'react-sticky';
import { styled } from 'styletron-react';

import DesktopWorksGrid from 'components/DesktopWorksGrid';
import Marquee from 'components/Marquee';
import MobileWorks from 'components/MobileWorks';
import StyledLink from 'components/StyledLink';
import WorkModal from 'components/WorkModal';

import FileUnder from 'components/project/FileUnder';
import Heading from 'components/project/Heading';
import IntroSection from 'components/project/IntroSection';
import StorySection from 'components/project/StorySection';
import ProjectBodySection from 'components/ProjectBodySection';

import GridRow from 'components/grid/Row';
import GridColumn from 'components/grid/Column';

import colorConstants from 'constants/colorConstants';
import mediaConstants from 'constants/mediaConstants';
import typographyConstants from 'constants/typographyConstants';

import usePrevious from 'hooks/usePrevious';
import useWindowSize from 'hooks/useWindowSize';

const Mobile = styled('div', {
  paddingTop: '2.5rem',
  position: 'relative',

  [`${mediaConstants.desktop}`]: {
    display: 'none',
  },
});

const Desktop = styled('div', {
  display: 'none',

  [`${mediaConstants.desktop}`]: {
    display: 'block',
  },
});

const ColumnContainer = styled('div', {
  paddingTop: '1.5rem',
});

const DesktopNavLinkContainer = styled('div', props => ({
  backgroundColor: props.selected
    ? colorConstants.lightGreen
    : colorConstants.white,
  borderBottom: `1px solid ${colorConstants.darkBlue}`,
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}));

const navLinkStyles = visible => ({
  display: 'block',
  fontSize: '1.5rem',
  padding: '2.5rem 0',
  textDecoration: 'none',
  visibility: visible ? 'visible' : 'hidden',
  ...typographyConstants.fleischWolf,
});

export default function ProjectDetails(props) {
  const { history, poseComplete, project, slugNext, slugPrev } = props;

  const windowSize = useWindowSize();
  const numColumnsImages = windowSize.width && windowSize.width < 1920 ? 9 : 7;
  const numColumnsHeading =
    windowSize.width && windowSize.width < 1920 ? 12 : 10;
  const [selectedWorkId, setSelectedWorkId] = useState(null);
  const previouslySelectedWorkId = usePrevious(selectedWorkId);

  const [previousHovered, setPreviousHovered] = useState(false);
  const [nextHovered, setNextHovered] = useState(false);

  const handleGoToPrev = () => {
    Events.scrollEvent.register('end', (to, element) => {
      history.push(`/projects/${slugPrev}`);
    });

    scroll.scrollToTop({
      duration: 500,
    });
  };

  const handleGoToNext = () => {
    Events.scrollEvent.register('end', (to, element) => {
      history.push(`/projects/${slugNext}`);
    });

    scroll.scrollToTop({
      duration: 500,
    });
  };

  return (
    <StickyContainer>
      <GridRow>
        <GridColumn num={numColumnsHeading}>
          <Sticky>
            {({ style }) => {
              return (
                <Heading
                  history={history}
                  slugNext={slugNext}
                  slugPrev={slugPrev}
                  style={style}
                >
                  {project.name}
                </Heading>
              );
            }}
          </Sticky>
        </GridColumn>
      </GridRow>

      {windowSize.width < 1200 && (
        <Mobile>
          <FileUnder text={project.file} />

          <IntroSection text={project.intro} />

          <MobileWorks
            onSelectWork={setSelectedWorkId}
            parentPoseComplete={poseComplete}
            works={_slice(project.works, 0, 1)}
          />

          <StorySection text={project.story} />

          <MobileWorks
            onSelectWork={setSelectedWorkId}
            parentPoseComplete={poseComplete}
            works={_slice(project.works, 1, 4)}
          />

          <ProjectBodySection heading="Results" source={project.results} />

          <MobileWorks
            onSelectWork={setSelectedWorkId}
            parentPoseComplete={poseComplete}
            works={_slice(project.works, 4)}
          />
        </Mobile>
      )}

      {windowSize.width >= 1200 && (
        <Desktop>
          <GridRow>
            <GridColumn num={3}>
              <ColumnContainer>
                <FileUnder text={project.file} />

                <IntroSection text={project.intro} />

                <StorySection text={project.story} />

                <ProjectBodySection
                  heading="Results"
                  source={project.results}
                />
              </ColumnContainer>
            </GridColumn>

            <GridColumn num={numColumnsImages}>
              {_map(project.campaigns, (campaign, index) => (
                <DesktopWorksGrid
                  campaignTitle={campaign.title}
                  index={index}
                  key={campaign.id}
                  onSelectWork={setSelectedWorkId}
                  parentPoseComplete={poseComplete}
                  worksGrid={campaign.worksGrid}
                />
              ))}
            </GridColumn>

            {windowSize.width >= 1920 && (
              <GridColumn num={2}>
                <Sticky topOffset={46}>
                  {({ style }) => {
                    return (
                      <div style={style}>
                        <DesktopNavLinkContainer
                          onClick={handleGoToPrev}
                          onMouseEnter={() => {
                            setPreviousHovered(true);
                          }}
                          onMouseLeave={() => {
                            setPreviousHovered(false);
                          }}
                          selected={previousHovered}
                        >
                          <StyledLink
                            styles={navLinkStyles(!previousHovered)}
                            to={`/projects/${slugPrev}`}
                          >
                            {'< Previous Project'}
                          </StyledLink>

                          <Marquee
                            $textStyles={navLinkStyles(previousHovered)}
                            visible={previousHovered}
                          >
                            {_times(10, n => (
                              <React.Fragment key={n}>
                                {'< Previous Project'}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </React.Fragment>
                            ))}
                          </Marquee>
                        </DesktopNavLinkContainer>

                        <DesktopNavLinkContainer
                          onClick={handleGoToNext}
                          onMouseEnter={() => {
                            setNextHovered(true);
                          }}
                          onMouseLeave={() => {
                            setNextHovered(false);
                          }}
                          selected={nextHovered}
                        >
                          <StyledLink
                            styles={navLinkStyles(!nextHovered)}
                            to={`/projects/${slugNext}`}
                          >
                            {'Next Project >'}
                          </StyledLink>

                          <Marquee
                            $textStyles={navLinkStyles(nextHovered)}
                            visible={nextHovered}
                          >
                            {_times(10, n => (
                              <React.Fragment key={n}>
                                {'Next Project >'}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </React.Fragment>
                            ))}
                          </Marquee>
                        </DesktopNavLinkContainer>
                      </div>
                    );
                  }}
                </Sticky>
              </GridColumn>
            )}
          </GridRow>
        </Desktop>
      )}

      {!_isEmpty(project.works) && (
        <WorkModal
          previouslySelectedWorkId={previouslySelectedWorkId}
          projectName={project.name}
          selectedWorkId={selectedWorkId}
          onSelectWork={setSelectedWorkId}
          windowSize={windowSize}
          works={project.works}
        />
      )}
    </StickyContainer>
  );
}
