import _map from 'lodash/map';
import { styled } from 'styletron-react';
import React from 'react';

import ProjectListItem from 'components/ProjectListItem';

import useWindowSize from 'hooks/useWindowSize';

const Container = styled('ol');

export default function ProjectList(props) {
  const {
    onSelectProject,
    projects,
    selectedProjectId,
  } = props;

  const windowSize = useWindowSize();

  return (
    <Container>
      {_map(projects, (project) => (
        <ProjectListItem
          key={project.id}
          onSelectProject={onSelectProject}
          project={project}
          selected={selectedProjectId === project.id}
          windowSize={windowSize}
        />
      ))}
    </Container>
  );
}
